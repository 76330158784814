import { DeleteOutlined, PoweroffOutlined, UserAddOutlined } from '@ant-design/icons';
import {
  Button,
  Col,
  DatePicker,
  Form,
  Input,
  Layout,
  message,
  Modal,
  Popconfirm,
  Row,
  Select,
  Space,
  Spin,
  Table,
  Tag,
  Tooltip,
  Typography,
} from 'antd';
import { useForm } from 'antd/es/form/Form';
import { useAuthConnectionEffect } from 'hooks/useAuthConnectionEffect';
import { DotPayload } from 'interfaces';
import moment, { Moment } from 'moment-timezone';
import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import * as CompaniesSlice from 'slices/companies';
import { useCompanies } from 'slices/companies';
import * as DotSlice from 'slices/dots';
import * as DotsSlice from 'slices/dots';
import { CreateDotPayload, useDots } from 'slices/dots';
import * as DriversSlices from 'slices/drivers';
import { useDrivers } from 'slices/drivers';
import { useMyAccount } from 'slices/myAccount';
import { useAppDispatch } from 'store/store';
import { fuzzySelectFilter, getContainer } from '../utils/html';

const DotsPage = () => {
  const [
    companyId,
    setCompanyId,
  ] = useState<string | null>(null);
  const { companiesById, companiesLoading, companiesSubscribed } = useCompanies();
  const { driversById, driversSubscribed, driversLoading } = useDrivers(companyId);
  const { dots, dotsSubscribed, dotsLoading } = useDots();
  const { myAccount, myAccountLoading, myAccountSubscribed } = useMyAccount();

  const [form] = useForm<CreateDotPayload>();
  const [
    showModal,
    setShowModal,
  ] = useState<boolean>(false);

  const appDispatch = useAppDispatch();
  const dispatch = useDispatch();

  useAuthConnectionEffect(() => {
    dispatch(DotSlice.subscribe());
    return () => {
      dispatch(DotSlice.unsubscribe());
    };
  }, []);

  useAuthConnectionEffect(() => {
    if (companyId) {
      dispatch(DriversSlices.subscribe(companyId));
      return () => {
        dispatch(DriversSlices.unsubscribe(companyId));
      };
    }
  }, [
    companyId,
  ]);

  useAuthConnectionEffect(() => {
    dispatch(CompaniesSlice.subscribe());
    return () => {
      dispatch(CompaniesSlice.unsubscribe());
    };
  }, []);

  return (
    <Spin spinning={dotsLoading || !dotsSubscribed || myAccountLoading || !myAccountSubscribed}>
      <Layout className={'accounts-list-container'}>
        <Layout.Content>
          <Row justify={'space-between'}>
            <Col span={6}>
              <Typography.Title style={{ margin: '16px 0' }} level={3}>
                DOT
              </Typography.Title>
            </Col>
            {[
              'admin',
              'manager',
            ].includes(myAccount?.role || '') ? (
              <Col
                span={3}
                style={{
                  display: 'flex',
                  justifyContent: 'flex-end',
                  alignItems: 'center',
                }}
              >
                <Button icon={<UserAddOutlined />} key="createButton" type="primary" onClick={() => setShowModal(true)}>
                  CREATE
                </Button>
                <Modal
                  visible={showModal}
                  title="CREATE DOT"
                  okText="CREATE"
                  cancelText="CANCEL"
                  onCancel={() => {
                    setShowModal(false);
                  }}
                  onOk={async () => {
                    form.submit();
                  }}
                  confirmLoading={companiesLoading || !companiesSubscribed}
                >
                  <Form
                    form={form}
                    layout="vertical"
                    name="form_in_modal"
                    onFinish={async (values) => {
                      const { status, msg } = await appDispatch(DotSlice.create(values));
                      if (status === 'ok') {
                        form.resetFields();
                        setShowModal(false);
                        message.success('Dot has been created');
                      } else {
                        message.error(msg || 'Error');
                      }
                    }}
                  >
                    <Form.Item name="from" label="From" hidden />
                    <Form.Item
                      name="block_date"
                      label="BLOCK DATE"
                      rules={[
                        {
                          required: true,
                          message: 'Choose lock date',
                        },
                      ]}
                    >
                      <DatePicker
                        format="MMM DD, YYYY"
                        onChange={(value: Moment | null) => {
                          form.setFields([
                            {
                              name: 'from',
                              value: value ? moment.utc(value.format('YYYY-MM-DD')).toDate() : null,
                            },
                          ]);
                        }}
                      />
                    </Form.Item>
                    <Form.Item
                      name="companyId"
                      label="COMPANY"
                      className="collection-create-form_last-form-item"
                      rules={[
                        {
                          required: true,
                          message: 'Please choose company',
                        },
                      ]}
                    >
                      <Select<string, { label: string; value: string }>
                        placeholder="Select company"
                        showSearch
                        getPopupContainer={getContainer}
                        loading={companiesLoading}
                        disabled={!companiesSubscribed}
                        options={Object.values(companiesById).map((company) => ({
                          label: company.name,
                          value: company._id,
                        }))}
                        onChange={(companyId) => {
                          form.setFields([{ name: 'driverId', value: null }]);
                          setCompanyId(companyId);
                        }}
                        filterOption={fuzzySelectFilter}
                        style={{ minWidth: 300 }}
                      />
                    </Form.Item>
                    <Form.Item
                      name="driverId"
                      label="DRIVER"
                      className="collection-create-form_last-form-item"
                      rules={[
                        {
                          required: true,
                          message: 'Please choose driver',
                        },
                      ]}
                    >
                      <Select<string, { label: string; value: string }>
                        placeholder="Select driver"
                        showSearch
                        getPopupContainer={getContainer}
                        loading={driversLoading}
                        disabled={!driversSubscribed}
                        options={Object.values(driversById || {}).map((driver) => ({
                          label: `${driver.firstName} ${driver.lastName}`,
                          value: driver._id,
                        }))}
                        filterOption={fuzzySelectFilter}
                        style={{ minWidth: 300 }}
                      />
                    </Form.Item>
                    <Form.Item
                      name="message"
                      label="DESCRIPTION"
                      rules={[
                        {
                          required: true,
                          message: 'Description is required',
                        },
                      ]}
                    >
                      <Input />
                    </Form.Item>
                  </Form>
                </Modal>
              </Col>
            ) : null}
          </Row>
          <div className={'content'}>
            <Table
              pagination={{
                pageSizeOptions: [
                  '10',
                  '50',
                  '100',
                  '250',
                  '500',
                  '1000',
                ],
                showSizeChanger: true,
              }}
              dataSource={dots}
            >
              <Table.Column
                title="DRIVER"
                dataIndex="driverName"
                key="driverName"
                render={(driverName: string) => {
                  return driverName ? <Tooltip title={driverName}>{driverName}</Tooltip> : '-';
                }}
              />
              <Table.Column
                title="COMPANY"
                dataIndex="companyName"
                key="companyName"
                render={(companyName: string) => {
                  return companyName ? <Tooltip title={companyName}>{companyName}</Tooltip> : '-';
                }}
              />
              <Table.Column
                title="LOCK DATE"
                dataIndex="from"
                key="from"
                render={(_, dotPayload: DotPayload) => {
                  return `${dotPayload.from ? moment.utc(dotPayload.from).format('MMM DD, YYYY') : 'N/A'}`;
                }}
              />

              <Table.Column
                title="STATUS"
                dataIndex="disabled"
                key="from"
                render={(disabled) => {
                  return disabled ? <Tag color="default">DISABLED</Tag> : <Tag color="processing">ENABLED</Tag>;
                }}
              />

              <Table.Column
                title="CREATED (DATE/TIME)"
                dataIndex="createdAt"
                key="createdAt"
                render={(createdAt: string) => {
                  return createdAt ? moment(createdAt).format('MMM DD, YYYY HH:mm') : '-';
                }}
              />

              {[
                'admin',
                'manager',
              ].includes(myAccount?.role || '') ? (
                <>
                  <Table.Column
                    dataIndex="clientResourceId"
                    key="clientResourceId"
                    render={(_, dot: DotPayload) => {
                      const { disabled } = dot;
                      return (
                        <Space size="small">
                          <Tooltip title="Finish patch" placement={'bottom'}>
                            <Popconfirm
                              title={`${disabled ? 'Enable' : 'Disable'} this DOT?`}
                              onConfirm={async () => {
                                const { status, msg } = await appDispatch(DotsSlice.disableDot(dot, !disabled));
                                if (status === 'ok') {
                                  message.success(`Dot has been ${disabled ? 'enabled' : 'disabled'}`);
                                } else {
                                  message.error(msg || 'Error');
                                }
                              }}
                              okText="YES"
                              cancelText="NO"
                            >
                              <Button
                                shape="circle"
                                danger={!disabled}
                                icon={<PoweroffOutlined className={'icon-medium'} />}
                              />
                            </Popconfirm>
                          </Tooltip>

                          <Tooltip title="Finish patch" placement={'bottom'}>
                            <Popconfirm
                              title={'DELETE DOT ?'}
                              onConfirm={async () => {
                                const { status, msg } = await appDispatch(DotsSlice.deleteDot(dot));
                                if (status === 'ok') {
                                  message.success('DOT BLOCK - DELETED');
                                } else {
                                  message.error(msg || 'Error');
                                }
                              }}
                              okText="YES"
                              cancelText="NO"
                            >
                              <Button shape="circle" icon={<DeleteOutlined className={'icon-medium'} />} />
                            </Popconfirm>
                          </Tooltip>
                        </Space>
                      );
                    }}
                  />
                </>
              ) : null}
            </Table>
          </div>
        </Layout.Content>
      </Layout>
    </Spin>
  );
};
export default DotsPage;

import { AlertOutlined } from '@ant-design/icons';
import {
  Button,
  Col,
  DatePicker,
  Form,
  Input,
  Layout,
  message,
  Modal,
  Row,
  Select,
  Space,
  Spin,
  Table,
  Tooltip,
  Typography,
} from 'antd';
import { useForm } from 'antd/es/form/Form';
import { useAuthConnectionEffect } from 'hooks/useAuthConnectionEffect';
import { AlertPayload } from 'interfaces';
import moment, { Moment } from 'moment-timezone';
import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import * as AlertsSlice from 'slices/alerts';
import { CreateAlertPayload, UpdateAlertPayload, useAlerts } from 'slices/alerts';
import * as CompaniesSlice from 'slices/companies';
import { useCompanies } from 'slices/companies';
import * as DriversSlices from 'slices/drivers';
import { useDrivers } from 'slices/drivers';
import { useMyAccount } from 'slices/myAccount';
import { useAppDispatch } from 'store/store';
import { fuzzySelectFilter, getContainer } from '../utils/html';

const AlertsPage = () => {
  const [
    companyId,
    setCompanyId,
  ] = useState<string | null>(null);
  const [
    showModal,
    setShowModal,
  ] = useState<boolean>(false);
  const [
    alertToUpdate,
    setAlertToUpdate,
  ] = useState<AlertPayload | null>(null);

  const { alerts, alertsLoading, alertsSubscribed } = useAlerts();
  const { myAccount, myAccountLoading, myAccountSubscribed } = useMyAccount();
  const { companiesById, companiesLoading, companiesSubscribed } = useCompanies();
  const { driversById, driversSubscribed, driversLoading } = useDrivers(companyId);

  const appDispatch = useAppDispatch();
  const dispatch = useDispatch();

  type ModalFormType = { id?: string; date_picker?: Moment | null } & CreateAlertPayload;

  const [form] = useForm<ModalFormType>();

  useAuthConnectionEffect(() => {
    dispatch(AlertsSlice.subscribe());
    return () => {
      dispatch(AlertsSlice.unsubscribe());
    };
  }, []);

  useAuthConnectionEffect(() => {
    dispatch(CompaniesSlice.subscribe());
    return () => {
      dispatch(CompaniesSlice.unsubscribe());
    };
  }, []);

  useAuthConnectionEffect(() => {
    if (companyId) {
      dispatch(DriversSlices.subscribe(companyId));
      return () => {
        dispatch(DriversSlices.unsubscribe(companyId));
      };
    }
  }, [companyId]);

  return (
    <Spin spinning={alertsLoading || !alertsSubscribed || myAccountLoading || !myAccountSubscribed}>
      <Layout className={'accounts-list-container'}>
        <Layout.Content>
          <Row justify={'space-between'}>
            <Col span={6}>
              <Typography.Title style={{ margin: '16px 0' }} level={3}>
                ALERTS
              </Typography.Title>
            </Col>
            {[
              'admin',
              'manager',
              'tech_support',
            ].includes(myAccount?.role || '') ? (
              <Col
                span={3}
                style={{
                  display: 'flex',
                  justifyContent: 'flex-end',
                  alignItems: 'center',
                }}
              >
                <Button icon={<AlertOutlined />} key="createButton" type="primary" onClick={() => setShowModal(true)}>
                  CREATE
                </Button>
                <Modal
                  visible={showModal}
                  title={form.getFieldValue('id') ? 'UPDATE ALERT' : 'CREATE ALERT'}
                  okText={form.getFieldValue('id') ? 'UPDATE' : 'CREATE'}
                  cancelText="CANCEL"
                  onCancel={() => {
                    form.resetFields();
                    setCompanyId(null);
                    setShowModal(false);
                  }}
                  onOk={() => {
                    form.submit();
                  }}
                  confirmLoading={companiesLoading || !companiesSubscribed}
                  forceRender
                >
                  <Form
                    form={form}
                    layout="vertical"
                    name="form_in_modal"
                    onFinish={async (values) => {
                      const id = values.id;
                      const { status, msg } = await appDispatch(
                        id
                          ? AlertsSlice.update(
                              {
                                ...(values as UpdateAlertPayload),
                                clientResourceId: (alertToUpdate as AlertPayload).clientResourceId,
                              },
                              alertToUpdate as AlertPayload
                            )
                          : AlertsSlice.create(values)
                      );
                      if (status === 'ok') {
                        form.resetFields();
                        setShowModal(false);
                        message.success(`Alert has been ${id ? 'updated' : 'created'}`);
                      } else {
                        message.error(msg || 'Error');
                      }
                    }}
                  >
                    <Form.Item name="id" label="ID" hidden></Form.Item>
                    <Form.Item name="to" label="To" hidden></Form.Item>
                    <Form.Item name="date_picker" label="END DATE (INCLUSIVE)">
                      <DatePicker
                        allowClear={true}
                        format="MMM DD, YYYY"
                        onChange={(value: Moment | null) => {
                          form.setFields([
                            {
                              name: 'to',
                              value: value ? moment.utc(value.format('YYYY-MM-DD')).toDate() : null,
                            },
                          ]);
                        }}
                      />
                    </Form.Item>
                    <Form.Item
                      name="companyId"
                      label="COMPANY"
                      className="collection-create-form_last-form-item"
                      rules={[
                        {
                          required: true,
                          message: 'Please choose company',
                        },
                      ]}
                    >
                      <Select<string, { label: string; value: string }>
                        placeholder="Select company"
                        showSearch
                        getPopupContainer={getContainer}
                        loading={companiesLoading}
                        disabled={!companiesSubscribed}
                        options={Object.values(companiesById).map((company) => ({
                          label: company.name,
                          value: company._id,
                        }))}
                        onChange={(companyId) => {
                          form.setFields([{ name: 'driverId', value: null }]);
                          setCompanyId(companyId);
                        }}
                        filterOption={fuzzySelectFilter}
                        style={{ minWidth: 300 }}
                      />
                    </Form.Item>
                    <Form.Item name="driverId" label="DRIVER" className="collection-create-form_last-form-item">
                      <Select<string, { label: string; value: string }>
                        placeholder="Select driver"
                        showSearch
                        allowClear
                        getPopupContainer={getContainer}
                        loading={driversLoading}
                        disabled={!driversSubscribed || companyId === null}
                        options={Object.values(driversById || {}).map((driver) => ({
                          label: `${driver.firstName} ${driver.lastName}`,
                          value: driver._id,
                        }))}
                        filterOption={fuzzySelectFilter}
                        style={{ minWidth: 300 }}
                      />
                    </Form.Item>
                    <Form.Item
                      name="message"
                      label="MESSAGE"
                      rules={[
                        {
                          required: true,
                          message: 'Message is required',
                        },
                      ]}
                    >
                      <Input />
                    </Form.Item>
                  </Form>
                </Modal>
              </Col>
            ) : null}
          </Row>
          <div className={'content'}>
            <Table
              pagination={{
                pageSizeOptions: [
                  '10',
                  '50',
                  '100',
                  '250',
                  '500',
                  '1000',
                ],
                showSizeChanger: true,
              }}
              dataSource={alerts}
              rowKey="id"
            >
              <Table.Column
                title="DRIVER"
                dataIndex="driverName"
                key="driverName"
                render={(driverName: string) => {
                  return driverName ? <Tooltip title={driverName}>{driverName}</Tooltip> : '-';
                }}
              />
              <Table.Column
                title="COMPANY"
                dataIndex="companyName"
                key="companyName"
                render={(companyName: string) => {
                  return companyName ? <Tooltip title={companyName}>{companyName}</Tooltip> : '-';
                }}
              />
              <Table.Column
                title="END"
                dataIndex="to"
                key="to"
                render={(_, alertPayload: AlertPayload) => {
                  return `${alertPayload.to ? moment.utc(alertPayload.to).format('MMM DD, YYYY') : 'N/A'}`;
                }}
              />
              <Table.Column
                title="CREATED (DATE/TIME)"
                dataIndex="createdAt"
                key="createdAt"
                render={(createdAt: string) => {
                  return createdAt ? moment(createdAt).format('MMM DD, YYYY HH:mm') : '-';
                }}
              />
              {[
                'admin',
                'manager',
                'tech_support',
              ].includes(myAccount?.role || '') ? (
                <Table.Column
                  dataIndex="clientResourceId"
                  key="clientResourceId"
                  render={(_, alert: AlertPayload) => (
                    <Space>
                      <Button
                        onClick={() => {
                          form.setFieldsValue({
                            id: alert.id,
                            to: alert.to || null,
                            date_picker: alert.to ? moment(alert.to) : null,
                            companyId: alert.companyId,
                            driverId: alert.driverId,
                            message: alert.message,
                          });
                          setCompanyId(alert.companyId);
                          setAlertToUpdate(alert);
                          setShowModal(true);
                        }}
                      >
                        EDIT
                      </Button>
                      <Button
                        onClick={async () => {
                          const { status, msg } = await appDispatch(AlertsSlice.deleteAlert(alert));
                          if (status === 'ok') {
                            message.success('Alert has been deleted');
                          } else {
                            message.error(msg || 'Error');
                          }
                        }}
                      >
                        DELETE
                      </Button>
                    </Space>
                  )}
                />
              ) : null}
            </Table>
          </div>
        </Layout.Content>
      </Layout>
    </Spin>
  );
};

export default AlertsPage;

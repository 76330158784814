export const timezones: {
  UTC: string;
  HAT: string;
  AKT: string;
  PT: string;
  MST: string;
  MT: string;
  CT: string;
  ET: string;
  AT: string;
  NT: string;
} = {
  UTC: 'UTC',
  HAT: 'Pacific/Honolulu',
  AKT: 'America/Anchorage',
  PT: 'America/Los_Angeles',
  MST: 'America/Denver',
  MT: 'America/Denver',
  CT: 'America/Chicago',
  ET: 'America/New_York',
  AT: 'America/Moncton',
  NT: 'America/St_Johns',
};

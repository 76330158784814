export const engineHoursErrors = [
  'Engine hours value is incorrect.',
  'Engine hours is required.',
  'Engine hours must remain the same as the previous or be greater.',
  'Engine hours must remain the same as the previous.',
  'Engine hours must be greater than the previous.',
];

export const odometerErrors = [
  'Odometer is required.',
  'Odometer must remain the same as the previous or be greater.',
  'Odometer must remain the same as the previous.',
  'Odometer must be greater than the previous.',
  "The event doesn't have previous event to check odometer.",
];

export const sphericalDistanceErrors = [
  'The spherical distance between this and previous location is larger than the change in odometer. \\(-?\\d+ vs -?\\d+ miles\\)',
];

export function removeErrors(errorsToRemove: string[], errorsString?: string) {
  errorsToRemove.forEach((error) => {
    const re = new RegExp(`${error}\n?`);
    errorsString = errorsString?.replace(re, '');
  });

  return errorsString;
}
